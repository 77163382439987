<template>
  <div id="nav" :class="$style.header">
    <div :class="$style.title">
      <ul :class="$style.breadcrumb">
        <li>
          <router-link to="/">Меню</router-link>
        </li>
        <li v-for="(link, index) in curBreadcrumb" :key="index">
          <span v-if="index === curBreadcrumb.length - 1">
            {{ link.text }}
          </span>
          <router-link v-else :to="getRouterLink(link.to)">
            {{ link.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbsHeader',
  props: {
    breadcrumb: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    curBreadcrumb() {
      return this.breadcrumb[this.$route.name] ?? []
    },
  },
  methods: {
    getRouterLink(to) {
      for (let key in this.$route.params) {
        to = to.replaceAll(`:${key}`, this.$route.params[key])
      }
      return to
    },
  },
}
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0.75rem 5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.15);
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      list-style: none;
      &:not(:first-child) {
        margin-left: 0.688rem;
        &::before {
          content: '/';
          color: $dark-gray;
        }
      }
      > a {
        transition: all 0.5s ease;
        &:hover {
          color: $black-gray;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span,
    a {
      font-size: 1.2rem;
      font-weight: bold;
      color: $dark-gray;
    }

    a {
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover,
      &.active {
        color: $black-gray;
      }
    }
  }
  svg {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
}
</style>
