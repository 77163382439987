import {
  ADDEVENT_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  MANAGERS_ROUTES,
  PROFILE_ROUTES,
} from '@/constants/routing'

export const BREADCRUMBS = {
  PROFILE: {
    profile: [
      {
        to: PROFILE_ROUTES.INDEX,
        text: 'Профиль',
      },
    ],
  },
  MANAGERS: {
    managers: [
      {
        to: MANAGERS_ROUTES.INDEX,
        text: 'Менеджеры',
      },
    ],
    createManager: [
      {
        to: MANAGERS_ROUTES.INDEX,
        text: 'Менеджеры',
      },
      {
        to: MANAGERS_ROUTES.CREATE,
        text: 'Создать',
      },
    ],
    editManager: [
      {
        to: MANAGERS_ROUTES.INDEX,
        text: 'Менеджеры',
      },
      {
        to: MANAGERS_ROUTES.UPDATE,
        text: 'Изменить',
      },
    ],
  },
}

export const ADDEVENT_BREADCRUMBS = {
  AddeventMenu: [{ text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX }],
  AddeventCatalog: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
  ],
  AddeventOrderCalendar: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    {
      text: 'Календарь Заказов',
      to: ADDEVENT_ADMIN_ROUTES.ORDERS_CALENDAR_INDEX,
    },
  ],
  AddeventSettings: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    {
      text: 'Настройки',
      to: ADDEVENT_ADMIN_ROUTES.SETTINGS_INDEX,
    },
  ],
  AddeventCategories: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
  ],
  AddeventCategoriesCatalog: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    {
      text: 'Каталог категории',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.CATALOG,
    },
  ],
  AddeventCategoriesCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.CREATE,
    },
  ],
  AddeventCategoriesEdit: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.UPDATE,
    },
  ],
  AddeventSolutions: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Готовые решения',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.LIST,
    },
  ],
  AddeventSolutionsCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Готовые решения',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.CREATE,
    },
  ],
  AddeventSolutionsEdit: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Готовые решения',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.EDIT,
    },
  ],

  AddeventProducts: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Продукты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.LIST,
    },
  ],
  AddeventProductsCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Продукты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.CREATE,
    },
  ],
  AddeventProductsEdit: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Продукты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.UPDATE,
    },
  ],
  AddeventBrands: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Бренды',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.BRANDS.LIST,
    },
  ],
  AddeventOrdersList: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDEVENT_ADMIN_ROUTES.ORDERS.LIST },
  ],
  AddeventOrdersCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDEVENT_ADMIN_ROUTES.ORDERS.LIST },
    { text: 'Создание', to: ADDEVENT_ADMIN_ROUTES.ORDERS.CREATE },
  ],
  AddeventOrdersUpdate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDEVENT_ADMIN_ROUTES.ORDERS.LIST },
    { text: 'Редактирование', to: ADDEVENT_ADMIN_ROUTES.ORDERS.UPDATE },
  ],
  AddeventTags: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Теги', to: ADDEVENT_ADMIN_ROUTES.CATALOG.TAGS.LIST },
  ],
  AddeventAssortments: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
  ],
  AddeventAssortmentsCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CREATE,
    },
  ],
  AddeventAssortmentsEdit: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.UPDATE,
    },
  ],
  AddeventBlog: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
  ],
  AddeventArticleCategories: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
  ],
  AddeventArticleCategoriesCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE,
    },
  ],
  AddeventArticleCategoriesUpdate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.UPDATE,
    },
  ],
  AddeventArticles: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Статьи',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
  ],
  AddeventArticlesCreate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Статьи',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
    {
      text: 'Создание',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.CREATE,
    },
  ],
  AddeventArticlesUpdate: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Блог', to: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Статьи',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.UPDATE,
    },
  ],
  AddeventContentMenu: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
  ],
  AddeventSeoTemplatesPage: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
    {
      text: 'SEO-Шаблоны',
      to: ADDEVENT_ADMIN_ROUTES.CONTENT.SEO_TEMPLATES.INDEX,
    },
  ],
  AddeventCommonPage: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
    {
      text: 'Общее',
      to: ADDEVENT_ADMIN_ROUTES.CONTENT.COMMON.INDEX,
    },
  ],
  AddeventWorkingWithUsPage: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
    {
      text: 'С нами работают',
      to: ADDEVENT_ADMIN_ROUTES.CONTENT.WORKING_WITH_US.INDEX,
    },
  ],
  AddeventContentPages: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
  ],
  AddeventContentEditPage: [
    { text: 'Addevent', to: ADDEVENT_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    { text: 'Редактирование', to: ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.UPDATE },
  ],
  default: [{ text: 'Addevent', link: ADDEVENT_ADMIN_ROUTES.INDEX }],
}

export const ADDWINE_BREADCRUMBS = {
  SignIn: [{ text: 'Вход в AddSellers', to: '/sellers/sign-in' }],
  AddwineMenu: [{ text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX }],
  AddwineGoodsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
  ],
  AddwineCategoriesTree: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
  ],
  AddwineCategoriesCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    { text: 'Создание', to: '/addwine/catalog/categories/create' },
  ],
  AddwineCategoriesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.CATEGORY,
    },
  ],
  AddwineCategoriesCatalog: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Категории',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.LIST,
    },
    {
      text: 'Каталог',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.CATALOG,
    },
  ],
  AddwineAssortmentsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
  ],
  AddwineAssortmentsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
    {
      text: 'Создание',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CREATE,
    },
  ],
  AddwineAssortmentsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.ASSORTMENT,
    },
  ],
  AddwineAssortmentsCatalog: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Ассортименты',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST,
    },
    {
      text: 'Каталог',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CATALOG,
    },
  ],
  AddwineGroupsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Группы товаров',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.LIST,
    },
  ],
  AddwineGroupsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Группы товаров',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.LIST,
    },
    { text: 'Создание', to: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.CREATE },
  ],
  AddwineGroupsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Группы товаров',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.GROUP,
    },
  ],
  AddwineAttributesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Атрибуты товаров', to: '/addwine/catalog/attributes' },
  ],
  AddwineAttributesRanking: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Атрибуты товаров', to: '/addwine/catalog/attributes' },
    { text: 'Ранжирование', to: '/addwine/catalog/attributes/ranking' },
  ],
  AddwineAttributeCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Атрибуты товаров', to: '/addwine/catalog/attributes' },
    { text: 'Создание', to: '/addwine/catalog/attributes/create' },
  ],
  AddwineAttributeEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Атрибуты товаров', to: '/addwine/catalog/attributes' },
    { text: 'Редактирование', to: '/addwine/catalog/attributes/:id' },
  ],
  AddwineBrandsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Список брендов',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.LIST,
    },
  ],
  AddwineBrandCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Список брендов',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.LIST,
    },
    {
      text: 'Создание бренда',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.CREATE,
    },
  ],
  AddwineBrandEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    {
      text: 'Список брендов',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.LIST,
    },
    {
      text: 'Редактирование',
      to: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.BRAND,
    },
  ],
  AddwineProductsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Продукты', to: '/addwine/catalog/products' },
  ],
  AddwineProductCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Продукты', to: '/addwine/catalog/products' },
    { text: 'Создание', to: '/addwine/catalog/products/create' },
  ],
  AddwineProductEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Продукты', to: '/addwine/catalog/products' },
    { text: 'Редактирование', to: '/addwine/catalog/products/:id' },
  ],
  AddwineProductSubscriptions: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Каталог', to: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
    { text: 'Продукты', to: '/addwine/catalog/products' },
    {
      text: 'Подписки',
      to: '/addwine/catalog/products/:id/subscriptions',
    },
  ],
  AddwinePaymentsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
  ],
  AddwinePaymentTypesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    { text: 'Способы оплаты', to: '/addwine/payments/payment-types' },
  ],
  AddwinePaymentTypesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    { text: 'Способы оплаты', to: '/addwine/payments/payment-types' },
    { text: 'Редактирование', to: '/addwine/payments/payment-types/:id' },
  ],
  AddwineOrderStatuses: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    { text: 'Статусы заказа', to: '/addwine/payments/order-statuses' },
  ],
  AddwinePaymentStatuses: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    {
      text: 'Статусы оплаты заказа',
      to: '/addwine/payments/payment-statuses',
    },
  ],
  AddwineDeliveryDaysSettings: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Настройки', to: ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX },
    {
      text: 'Настройки дней доставки',
      to: ADDWINE_ADMIN_ROUTES.SETTINGS.DELIVERY_DAYS.INDEX,
    },
  ],
  AddwineNotificationsSettings: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Настройки', to: ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX },
    {
      text: 'Настройки уведомлений',
      to: ADDWINE_ADMIN_ROUTES.SETTINGS.NOTIFICATIONS.INDEX,
    },
  ],
  AddwineOrdersList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDWINE_ADMIN_ROUTES.ORDERS_INDEX },
  ],
  AddwineOrderView: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDWINE_ADMIN_ROUTES.ORDERS_INDEX },
    { text: 'Просмотр заказа', to: '/addwine/orders/:id' },
  ],
  AddwineOrderEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDWINE_ADMIN_ROUTES.ORDERS_INDEX },
    { text: 'Редактирование заказа', to: '/addwine/orders/:id/edit' },
  ],
  AddwineOrderCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Заказы', to: ADDWINE_ADMIN_ROUTES.ORDERS_INDEX },
    { text: 'Создание заказа', to: '/addwine/orders/create' },
  ],
  AddwineLocationDeliveryList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    {
      text: 'Список локаций доставки',
      to: '/addwine/payments/locations-delivery',
    },
  ],
  AddwineLocationDeliveryCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    {
      text: 'Список локаций доставки',
      to: '/addwine/payments/locations-delivery',
    },
    {
      text: 'Создание локации',
      to: '/addwine/payments/locations-delivery/create',
    },
  ],
  AddwineLocationDeliveryEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Оплата и доставка',
      to: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
    },
    {
      text: 'Список локаций доставки',
      to: '/addwine/payments/locations-delivery',
    },
    {
      text: 'Редактирование локации',
      to: '/addwine/payments/locations-delivery/:id',
    },
  ],
  AddwineBlogsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
  ],
  AddwineBlogArticlesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Список статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
  ],
  AddwineBlogArticlesCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Список статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
    {
      text: 'Создание статьи',
      to: ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.CREATE,
    },
  ],
  AddwineBlogArticlesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Список статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.LIST,
    },
    {
      text: 'Редактирование статьи',
      to: ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.ARTICLE,
    },
  ],
  AddwineBlogArticleCategories: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
  ],
  AddwineBlogArticleCategoriesCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
    {
      text: 'Создание категории статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE,
    },
  ],
  AddwineBlogArticleCategoriesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Статьи', to: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
    {
      text: 'Категории статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.LIST,
    },
    {
      text: 'Редактирование категории статей',
      to: ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CATEGORY,
    },
  ],
  AddwinePromotionsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
  ],
  AddwinePromoArticlesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Список акций', to: '/addwine/promotions/promo-articles' },
  ],
  AddwinePromoArticlesCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Список акций', to: '/addwine/promotions/promo-articles' },
    {
      text: 'Создание акции',
      to: '/addwine/promotions/promo-articles/create',
    },
  ],
  AddwinePromoArticlesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Список акций', to: '/addwine/promotions/promo-articles' },
    {
      text: 'Редактирование акции',
      to: '/addwine/promotions/promo-articles/:id',
    },
  ],
  AddwinePromosMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
  ],
  AddwineAdPromosList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Рекламные промокоды',
      to: '/addwine/promotions/promos/ad-promos',
    },
  ],
  AddwineAdPromosCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Рекламные промокоды',
      to: '/addwine/promotions/promos/ad-promos',
    },
    {
      text: 'Создание',
      to: '/addwine/promotions/promos/ad-promos/create',
    },
  ],
  AddwineAdPromosEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Рекламные промокоды',
      to: '/addwine/promotions/promos/ad-promos',
    },
    {
      text: 'Редактирование',
      to: '/addwine/promotions/promos/ad-promos/:id',
    },
  ],
  AddwineGiftPromosList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Подарочные промокоды',
      to: '/addwine/promotions/promos/gift-promos',
    },
  ],
  AddwineGiftPromosCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Подарочные промокоды',
      to: '/addwine/promotions/promos/gift-promos',
    },
    {
      text: 'Создание',
      to: '/addwine/promotions/promos/gift-promos/create',
    },
  ],
  AddwineGiftPromosView: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Акции и промокоды',
      to: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
    },
    { text: 'Промокоды', to: '/addwine/promotions/promos' },
    {
      text: 'Подарочные промокоды',
      to: '/addwine/promotions/promos/gift-promos',
    },
    {
      text: 'Просмотр',
      to: '/addwine/promotions/promos/gift-promos/:id',
    },
  ],
  AddwineContentMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
  ],
  AddwineContentSeoTemplates: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-шаблоны', to: '/addwine/content/templates' },
  ],
  AddwineSeoTextsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-тексты', to: '/addwine/content/seo-texts' },
  ],
  AddwineSeoTextsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-тексты', to: '/addwine/content/seo-texts' },
    {
      text: 'Создание SEO-текста',
      to: '/addwine/content/seo-texts/create',
    },
  ],
  AddwineSeoTextsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-тексты', to: '/addwine/content/seo-texts' },
    {
      text: 'Редактирование SEO-текста',
      to: '/addwine/content/seo-texts/edit',
    },
  ],
  AddwineSEOFAQsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-FAQ', to: '/addwine/content/seo-faqs' },
  ],
  AddwineSEOFAQsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-FAQ', to: '/addwine/content/seo-faqs' },
    { text: 'Создание SEO-FAQ', to: '/addwine/content/seo-faqs/create' },
  ],
  AddwineSEOFAQsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO-FAQ', to: '/addwine/content/seo-faqs' },
    {
      text: 'Редактирование SEO-FAQ',
      to: '/addwine/content/seo-faqs/edit',
    },
  ],
  AddwineSubscriptionsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Подписки', to: ADDWINE_ADMIN_ROUTES.SUBSCRIPTIONS_INDEX },
  ],
  AddwineAnalytics: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Аналитика', to: ADDWINE_ADMIN_ROUTES.ANALYTICS_INDEX },
  ],
  AddwineFeedbacks: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Консультации', to: ADDWINE_ADMIN_ROUTES.FEEDBACKS_INDEX },
  ],
  AddwineSettingsPdf: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Настройки PDF',
      to: ADDWINE_ADMIN_ROUTES.SETTINGS_PDF_INDEX,
    },
  ],
  AddwineLoyaltyProgram: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Программа лояльности',
      to: ADDWINE_ADMIN_ROUTES.PROGRAM_LOYALTY_INDEX,
    },
  ],
  AddwineLoyaltyProgramEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Программа лояльности',
      to: ADDWINE_ADMIN_ROUTES.PROGRAM_LOYALTY_INDEX,
    },
    { text: 'Редактирование', to: ADDWINE_ADMIN_ROUTES.CONTENT.LOYALTY_PROGRAM.UPDATE },
  ],
  AddwineLoyaltyProgramCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Программа лояльности',
      to: ADDWINE_ADMIN_ROUTES.PROGRAM_LOYALTY_INDEX,
    },
    { text: 'Создание', to: ADDWINE_ADMIN_ROUTES.CONTENT.LOYALTY_PROGRAM.CREATE },
  ],
  AddwineUsersList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
  ],
  AddwineUsersCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
    { text: 'Создание пользователя', to: '/addwine/users/create' },
  ],
  AddwineUsersEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
    { text: 'Редактирование', to: '/addwine/users/:id' },
  ],
  AddwineUsersOrders: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
    { text: 'Редактирование', to: '/addwine/users/:id' },
  ],
  AddwineUsersSubscriptions: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
    { text: 'Редактирование', to: '/addwine/users/:id' },
  ],
  AddwineUsersBonusTransactions: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    {
      text: 'Список пользователей',
      to: ADDWINE_ADMIN_ROUTES.USERS_INDEX,
    },
    { text: 'Редактирование', to: '/addwine/users/:id' },
  ],
  AddwineSettingsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Настройки', to: ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX },
  ],
  AddwinePaymentSettings: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Настройки', to: ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX },
    {
      text: 'Настройки оплаты',
      to: ADDWINE_ADMIN_ROUTES.SETTINGS.PAYMENT.INDEX,
    },
  ],
  AddwineContentPages: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
  ],
  AddwineContactsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Контакты',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CONTACTS.INDEX,
    },
  ],
  AddwineAboutPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'О компании',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ABOUT.INDEX,
    },
  ],
  AddwineMainPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    { text: 'Главная', to: '/addwine/content/pages/main' },
  ],
  AddwineBlogPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Статьи',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ARTICLES.INDEX,
    },
  ],
  AddwineWarrantyPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Гарантия',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.WARRANTY.INDEX,
    },
  ],
  AddwineBecomeASupplierPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Стать поставщиком',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BECOME_A_SUPPLIER.INDEX,
    },
  ],
  AddwinePurchaseReturnsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Возврат товара',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_RETURNS.INDEX,
    },
  ],
  AddwinePurchaseCancellationPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Отмена покупки',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_CANCELLATION.INDEX,
    },
  ],
  AddwineBonusInfoPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Бонусная система',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BONUS_INFO.INDEX,
    },
  ],
  AddwineLoyaltyProgramInfoPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Программа лояльности',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.LOYALTY_PROGRAM_INFO.INDEX,
    },
  ],
  AddwinePolicyPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Политика конфиденциальности',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PRIVATE_POLICY.INDEX,
    },
  ],
  AddwineProcessingPolicyPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Политика обработки персональных данных',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROCESSING_POLICY.INDEX,
    },
  ],
  AddwinePublicOfferPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Публичная оферта',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.INDEX,
    },
  ],
  AddwineCertificatesPolicyPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Политика сертификатов',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CERTIFICATES_POLICY.INDEX,
    },
  ],
  AddwineFaqPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'FAQ',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.FAQ.INDEX,
    },
  ],
  AddwineCookiesPolicyPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Политика использования cookie',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COOKIES_POLICY.INDEX,
    },
  ],
  AddwineCataloguePage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Каталог',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CATALOGUE.INDEX,
    },
  ],
  AddwineCommonPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Общее',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COMMON.INDEX,
    },
  ],
  AddwineOrderInfoPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Как сделать заказ',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ORDER_INFO.INDEX,
    },
  ],
  AddwineClientsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Клиенты',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CLIENTS.INDEX,
    },
  ],
  AddwinePartnersPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Партнеры',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PARTNERS.INDEX,
    },
  ],
  AddwineVacanciesPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Вакансии',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.VACANCIES.INDEX,
    },
  ],
  AddwineDocumentsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Документы',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DOCUMENTS.INDEX,
    },
  ],
  AddwineDeliveryPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Доставка и оплата',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DELIVERY.INDEX,
    },
  ],
  AddwinePromotionsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Акции',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROMOTIONS.INDEX,
    },
  ],
  AddwineReviewsPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Отзывы',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.REVIEWS.INDEX,
    },
  ],
  AddwineTeamPage: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Страницы', to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
    {
      text: 'Команда проекта',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.TEAM.INDEX,
    },
  ],
  AddwineVacanciesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Вакансии', to: '/addwine/content/vacancies' },
  ],
  AddwineVacanciesCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Вакансии', to: '/addwine/content/vacancies' },
    {
      text: 'Создание вакансии',
      to: '/addwine/content/vacancies/create',
    },
  ],
  AddwineVacanciesEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Вакансии', to: '/addwine/content/vacancies' },
    {
      text: 'Редактирование вакансии',
      to: '/addwine/content/vacancies/edit',
    },
  ],
  AddwineReviewsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Отзывы', to: '/addwine/content/reviews' },
  ],
  AddwineReviewsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Отзывы', to: '/addwine/content/reviews' },
    { text: 'Создание отзыва', to: '/addwine/content/reviews/create' },
  ],
  AddwineReviewsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Отзывы', to: '/addwine/content/reviews' },
    {
      text: 'Редактирование отзыва',
      to: '/addwine/content/reviews/edit',
    },
  ],
  AddwineTeamMembersList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Команда проекта', to: '/addwine/content/team-members' },
  ],
  AddwineTeamMembersCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Команда проекта', to: '/addwine/content/team-members' },
    {
      text: 'Создание сотрудника',
      to: '/addwine/content/team-members/create',
    },
  ],
  AddwineTeamMembersEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Команда проекта', to: '/addwine/content/team-members' },
    {
      text: 'Редактирование сотрудника',
      to: '/addwine/content/team-members/edit',
    },
  ],
  AddwineFAQsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'FAQ', to: '/addwine/content/faqs' },
  ],
  AddwineFAQsCategoriesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'FAQ', to: '/addwine/content/faqs' },
    { text: 'Категории', to: '/addwine/content/faqs/categories' },
  ],
  AddwineFAQsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'FAQ', to: '/addwine/content/faqs' },
    { text: 'Список FAQ', to: '/addwine/content/faqs/faqs' },
  ],
  AddwineFAQsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'FAQ', to: '/addwine/content/faqs' },
    { text: 'Список FAQ', to: '/addwine/content/faqs/faqs' },
    { text: 'Создание', to: '/addwine/content/faqs/faqs/create' },
  ],
  AddwineFAQsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'FAQ', to: '/addwine/content/faqs' },
    { text: 'Список FAQ', to: '/addwine/content/faqs/faqs' },
    { text: 'Редактирование', to: '/addwine/content/faqs/faqs/edit' },
  ],
  AddwineClientsMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Клиенты', to: '/addwine/content/clients' },
  ],
  AddwineClientCategoriesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Клиенты', to: '/addwine/content/clients' },
    { text: 'Категории', to: '/addwine/content/clients/categories' },
  ],
  AddwineClientsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Клиенты', to: '/addwine/content/clients' },
    { text: 'Список клиентов', to: '/addwine/content/clients/clients' },
  ],
  AddwineClientsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Клиенты', to: '/addwine/content/clients' },
    { text: 'Список клиентов', to: '/addwine/content/clients/clients' },
    { text: 'Создание', to: '/addwine/clients/clients/create' },
  ],
  AddwineClientsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Клиенты', to: '/addwine/content/clients' },
    { text: 'Список клиентов', to: '/addwine/content/clients/clients' },
    { text: 'Редактирование', to: '/addwine/clients/clients/edit' },
  ],
  AddwinePartnersMenu: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Партнёры', to: '/addwine/content/partners' },
  ],
  AddwinePartnerCategoriesList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Партнёры', to: '/addwine/content/partners' },
    { text: 'Категории', to: '/addwine/content/partners/categories' },
  ],
  AddwinePartnersList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Партнёры', to: '/addwine/content/partners' },
    {
      text: 'Список партнёров',
      to: '/addwine/content/partners/partners',
    },
  ],
  AddwinePartnersCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Партнёры', to: '/addwine/content/partners' },
    {
      text: 'Список партнёров',
      to: '/addwine/content/partners/partners',
    },
    { text: 'Создание', to: '/addwine/partners/partners/create' },
  ],
  AddwinePartnersEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Партнёры', to: '/addwine/content/partners' },
    {
      text: 'Список партнёров',
      to: '/addwine/content/partners/partners',
    },
    { text: 'Редактирование', to: '/addwine/partners/partners/edit' },
  ],
  AddwineDocumentsList: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Документы', to: '/addwine/content/documents' },
  ],
  AddwineDocumentsCreate: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Документы', to: '/addwine/content/documents' },
    {
      text: 'Создание документа',
      to: '/addwine/content/documents/create',
    },
  ],
  AddwineDocumentsEdit: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'Документы', to: '/addwine/content/documents' },
    {
      text: 'Редактирование документа',
      to: '/addwine/content/documents/:id',
    },
  ],
  AddwineSeo: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.INDEX },
  ],
  AddwineSeoRobotsTxt: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.INDEX },
    {
      text: 'Robots txt',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.ROBOTS_TXT.INDEX,
    },
  ],
  AddwineSeoSitemap: [
    { text: 'Addwine', to: ADDWINE_ADMIN_ROUTES.INDEX },
    { text: 'Контент и SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
    { text: 'SEO', to: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.INDEX },
    {
      text: 'Sitemap',
      to: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.SITEMAP.INDEX,
    },
  ],
  default: [{ text: 'Addwine', link: ADDWINE_ADMIN_ROUTES.INDEX }],
}
